import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { SingleClubResponse, fetchClub } from '../../api/club/fetchClub';
import { DetailView } from '../../components/DetailView';
import { getReadableTimestampString } from '../../types/Appointment';
import { Season, SeasonConfig } from '../../types/Season';
import { WeekdayConfig } from '../../types/Weekday';
import { TrainingGroup, filterAndSortAppointments } from '../../types/training-group/TrainingGroup';
import { ContactInfo } from '../../components/ContactInfo';

interface Props {
    backendUrl: string
}

export const ClubDetailView: React.FC<Props> = ({ backendUrl }) => {
    const [{ club, trainingGroups }, setData] = useState<SingleClubResponse>({
        trainingGroups: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchClub(backendUrl, id)
                .then(data => {
                    setData(data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setError(true);
                    setLoading(false);
                })
        }
    }, [id, backendUrl])

    return (
        <DetailView
            loading={loading}
            error={error}
            notFoundMsg={"Zu Ihrer Anfrage konnte kein Verein gefunden werden."}
            entity={club}
        >
            {club &&
                <>
                    <div className="d-flex align-items-center">
                        <ChevronLeft className="clickable-icon me-2" onClick={() => navigate(-1)} />
                        <h4 className="m-0 text-primary">{club.name}</h4>
                    </div>
                    <hr className="my-3" />
                    <Row>
                        {(club.homepage || club.contact || club.socialMedia.length) ? <Col>
                            {(club.contact || club.homepage) ? <Row className="mb-4">
                                <Col>
                                    <h5 className="text-primary">Kontakt</h5>
                                    <ContactInfo club={club}></ContactInfo>
                                </Col>
                            </Row> : <></>}
                            {club.socialMedia.length ?
                                <Row className="mb-4">
                                    <Col>
                                        <h5 className="text-primary">Soziale Medien</h5>
                                        <div>
                                            {club.socialMedia.map((socialMedia, index) =>
                                                <div className="table-row">
                                                    <div className="text-primary first">{socialMedia.title}:</div>
                                                    <div className="second social-media">
                                                        <a target="_blank" rel="noreferrer" href={socialMedia.url}>
                                                            {socialMedia.url}
                                                        </a></div>
                                                </div>)
                                            }
                                        </div>
                                    </Col>
                                </Row> : <></>
                            }
                        </Col> : <></>}
                        <Col lg>
                            {club.sports.length !== 0 &&
                                <Row className="mb-4">
                                    <Col>
                                        <h5 className="text-primary">Sportarten</h5>
                                        <div>
                                            {club.sports.map(sport => (
                                                <div key={sport}>{sport}</div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            }
                            {trainingGroups.length ?
                                <Container fluid className="p-0 mb-4">
                                    <h5 className="text-primary">Trainingsgruppen</h5>
                                    {trainingGroups.map(trainingGroup => {
                                        return (
                                            <Row key={trainingGroup.id} className="training-group border rounded p-2 mx-0 mt-4" onClick={() => navigate("/training-group/" + trainingGroup.id)}>
                                                <Col>
                                                    <div className="fs-5 fw-bold mb-3 text-primary">
                                                        {trainingGroup.sport.name}
                                                    </div>
                                                    <div className="ms-2">
                                                        {getAppointmentsDivs(trainingGroup, Season.WHOLE_YEAR)}
                                                        {getAppointmentsDivs(trainingGroup, Season.SUMMER)}
                                                        {getAppointmentsDivs(trainingGroup, Season.WINTER)}
                                                    </div>
                                                </Col>
                                                <Col xs={1} className="align-self-center">
                                                    <ChevronRight />
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Container> : <></>
                            }
                        </Col>
                    </Row>
                </>
            }
        </DetailView>
    )
}

function getAppointmentsDivs(trainingGroup: TrainingGroup, season: Season) {
    const appointments = filterAndSortAppointments(trainingGroup.appointments, season);
    if (!appointments.length) return (<></>);
    return (<div className="ms-2 mb-2">
        <h6>
            {SeasonConfig[season].translation}
        </h6>
        <div className="ms-2">
            {appointments.map((appointment, index) => (
                <div key={index}>
                    <span style={{ display: "inline-block", width: 100 }}>{WeekdayConfig[appointment.weekday].translation}</span>{getReadableTimestampString(appointment)}
                </div>
            ))}
        </div>
    </div>);
}
